import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import uikit from "uikit"
import 'uikit/dist/css/uikit.min.css'
import '../styles/style.css'
import {Helmet} from "react-helmet";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope,faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'

// markup
const IndexPage = () => {
  return (
    <main>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Crystal Clean | Servis za čišćenje</title>
            <link rel="canonical" href="https://www.crystalclean.hr"/>
            <meta name="description" content="Mi smo Crystal Clean. Servis za čišćenje. Nudimo profesionalnu uslugu čišćenja poslovnih prostora, najmeštaja, stambenih objekata i slično."/>
        </Helmet>

         <div className="uk-hero">
        <div className="top-wrap uk-position-relative uk-light">
                    
        <div id="nav" className="nav" data-uk-sticky="cls-active: uk-background-nav uk-box-shadow-medium; top: 100vh; animation: uk-animation-slide-top">
            <div className="uk-container">
                <nav className="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                    <div className="uk-navbar-left">
                        <div className="uk-navbar-item uk-padding-remove-horizontal">
                            <a className="uk-logo" title="Logo" href="#">Crystal Clean</a>
                        </div>
                    </div>
                    <div className="uk-navbar-right">
                        <ul className="uk-navbar-nav uk-visible@s">
                            <li><a href="#service" data-uk-scroll>Usluge</a></li>
                            <li><a href="#about" data-uk-scroll>O nama</a></li>
                            <li><a href="#price" data-uk-scroll>Cjenik</a></li>
                            <li><a href="#contact" data-uk-scroll>Kontakt</a></li>
                        </ul>
                        <a className="uk-navbar-toggle uk-navbar-item uk-hidden@s" data-uk-toggle="target: #offcanvas-nav" data-uk-navbar-toggle-icon></a>
                    </div>
                </nav>
            </div>
        </div>


        </div>


        <div className="uk-section uk-section-xlarge uk-light">
            <div className="uk-container">

            <div>
                <p className="uk-heading-small uk-text-bold">Vi se opustite,<br/> mi čistimo.</p>
                <p className="uk-text-large">Profesionalna usluga čišćenja na <br/>klik udaljena od vas.</p>
            </div>

            </div>
        </div>
    </div>


    <div id="service" className="uk-section uk-section-large uk-section-default">
        <div className="uk-container">
        
            <div className="uk-grid-match uk-child-width-1-3@m uk-light" data-uk-grid>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-card-hover uk-service-1 uk-flex uk-flex-middle uk-width-1-1@m">
                            <h2 className="uk-h3 uk-align-center uk-text-center">Dubinsko<br/>čišćenje</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-card-hover uk-service-2 uk-flex uk-flex-middle uk-width-1-1@m">
                            <h2 className="uk-h3 uk-align-center uk-text-center">Čišćenje poslovnih prostora, stanova i kuća</h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-card-hover uk-service-3 uk-flex uk-flex-middle uk-width-1-1@m">
                            <h2 className="uk-h3 uk-align-center uk-text-center">Generalno čišćenje <br/>nakon radova</h2>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>


    <div id="about" className="uk-section uk-section-default uk-image-about">
        <div className="uk-container">
        
            <div className="uk-grid-match uk-child-width-1-2@m" data-uk-grid>
                <div>
                <StaticImage className="uk-border-rounded uk-hidden@s"
                    src="../images/about.jpg"
                    alt="About us"
                    layout="fixed"
                    placeholder="tracedSVG"
                    height={400}
                    formats={['auto','webp','avif']}
                />
                
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-about uk-width-1-1@m uk-light">
                            <h2>O nama</h2>
                            <p>Servis za čišćenje Crystal Clean nudi profesionalnu uslugu čišćenja poslovnih prostora, namještaja, stambenih objekata i slično.</p>
                            <p>Zadovoljstvo klijenata nam je na prvom mjestu i stoga Vam možemo garantirati kvalitetu, pouzdanost, vrhunsku opremu i temeljito čišćenje.</p>
                            <p>Donesite svoju najbolju odluku i Vaši poslovni i životni prostori će biti besprijekorno čisti.</p>
                            <p>Uz našu pomoć, imat ćete dovoljno vremena za svoju obitelj i prijatelje.</p>
                            <p>Obratite nam se s povjerenjem! <br></br>Vaš Crystal Clean.</p>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>




    <div id="price" className="uk-section uk-margin-large-top uk-section-default">
        <div className="uk-container">      

            <p className="uk-h2 uk-margin-medium">Cjenik</p>

            <div className="uk-grid-match uk-child-width-1-1@m uk-grid-small" data-uk-grid>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Dubinsko čišćenje namještaja</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">50-70kn po sjedećem mjestu</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Fotelja</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">80-100kn</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Dvosjed</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">100-160kn</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Trosjed</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">150-200kn</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Kutna garnitura</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">200-350kn</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Tabure za jednu osobu</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">50kn</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Čišćenje tepiha i tepisona</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">20kn po m2</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Stolica sa naslonom</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">25kn po komadu(samo sjedalo 15kn)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Čišćenje i pranje madraca</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">100-200kn ovisno o veličini</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Čišćenje stanova, kuća i poslovnih prostora</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">Cijena po dogovoru</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="uk-card uk-card-default uk-padding-small uk-card-body uk-border-rounded uk-price-card uk-width-1-1@m">
                            <div className="uk-grid-match uk-child-width-1-2 uk-light" data-uk-grid>
                                <div>
                                    <p className="uk-h4">Čišćenje nakon radova / Generalno čišćenje</p>
                                </div>
                                <div>
                                    <p className="uk-text-right uk-text-bold">Cijena po dogovoru</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
     
            </div>
            
        </div>
    </div>


    
    <div id="contact" className="uk-section uk-section-default">
        <div className="uk-container">
    
            <p className="uk-h2 uk-margin-medium">Kontakt</p>
    
            <form name="rezervacije" className="uk-grid-small" data-uk-grid method="post" data-netlify="true" action="/success/" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="rezervacije" />

                <div className="uk-width-1-2@s">
                    <label className="uk-form-label" for="form-stacked-text">Ime i prezime</label>
                    <input name="ime" id="ime"  className="uk-input uk-border-rounded" type="text" placeholder="" required/>
                </div>

                <div className="uk-width-1-2@s">
                    <label className="uk-form-label" for="form-stacked-text">Email</label>
                    <input name="email" id="email" className="uk-input uk-border-rounded" type="email" placeholder="" required/>
                </div>

                <div className="uk-width-1-2@s">
                    <label className="uk-form-label" for="form-stacked-text">Usluga</label>
                    <select name="usluga" className="uk-select uk-border-rounded" id="form-stacked-select">
                        <option>Dubinsko čišćenje</option>
                        <option>Čišćenje poslovnih prostora, stanova i kuća</option>
                        <option>Generalno čišćenje nakon radova</option>
                    </select>                
                </div>

                <div className="uk-width-1-2@s">
                    <label className="uk-form-label" for="form-stacked-text">Termin</label>
                    <input name="termin" id="termin" className="uk-input uk-border-rounded" type="text" placeholder=""/>
                </div>
            
                <div className="uk-width-1-2@s uk-margin-top">
                    <button className="uk-button uk-button-primary uk-border-rounded">Pošalji</button>
                </div>

            </form>
    
        </div>
    </div>




    <footer className="uk-section uk-section-secondary uk-padding-remove-bottom">
        <div className="uk-container">
            <div className="uk-grid uk-grid-small" data-uk-grid>
                <div className="uk-width-1-3@m uk-text-center">
                    <h4>Crystal Clean</h4>
                    <p>Branovečina 90<br></br>10 000 Zagreb</p>
                </div>
                <div className="uk-width-1-3@m">
                    <h5>KONTAKTIRAJTE NAS</h5>
                    <ul className="uk-list">
                        <li><FontAwesomeIcon className="icon" size="lg" icon={faEnvelope}/> crystalclean.vs@gmail.com</li>
                        <li><FontAwesomeIcon className="icon" size="lg" icon={faPhoneAlt}/>099 640 1392</li>
                        <li><FontAwesomeIcon className="icon" size="lg" icon={faPhoneAlt}/>099 564 3344</li>
                    </ul>
                </div>
                <div className="uk-width-1-3@m">
                    <h5>PRATITE NAS</h5>
                    <ul className="uk-list">
                        <li><FontAwesomeIcon className="icon" size="lg" icon={faInstagram}/>crystalclean.vs</li>  
                    </ul>
                </div>
                
            </div>
        </div>
        
        <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
            <span className="uk-text-small uk-text-muted">© 2022 Crystal Clean</span>
        </div>
    </footer>

 
    <div id="offcanvas-nav" data-uk-offcanvas="flip: true; overlay: false">
        <div className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide bkg">
            <button className="uk-offcanvas-close uk-close uk-icon" type="button" data-uk-close></button>
            <ul className="uk-nav uk-nav-default">
                <li className="uk-active"><a href="#service" data-uk-scroll>Usluge</a></li>
                <li className="uk-active"><a href="#about" data-uk-scroll>O nama</a></li>
                <li className="uk-active"><a href="#price" data-uk-scroll>Cjenik</a></li>
                <li className="uk-active"><a href="#contact" data-uk-scroll>Kontakt</a></li>
            </ul>
        </div>
    </div>

    </main>
  )
}

export default IndexPage
